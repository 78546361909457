import {BiEdit} from "react-icons/bi";
import {useEffect, useState} from "react";
import {couponService} from "../../../_services";
import CouponsConfigurationsForm from "./CouponsConfigurationsForm";

const CouponsConfigurations = () => {
    const [CouponConfigurationsData, setCouponConfigurationsData] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [hidden, setHidden] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await couponService.getCouponConfigurations();
                setCouponConfigurationsData(data);
            } catch (error) {
                console.error("Error fetching coupon configurations data:", error);
            }
        };

        fetchData();
    }, [refresh]);
    const setFormHidden = () => {
        setHidden(!hidden);
    }
    if (CouponConfigurationsData) {
        return (
            <>
                <div className={hidden ? "d-none" : "d-block"}>
                    <CouponsConfigurationsForm
                        settings_id={CouponConfigurationsData.id}
                        discount={CouponConfigurationsData.discount}
                        first_message_time={CouponConfigurationsData.first_message_time}
                        second_message_time={CouponConfigurationsData.second_message_time}
                        first_message={CouponConfigurationsData.first_message}
                        second_message={CouponConfigurationsData.second_message}
                        third_message={CouponConfigurationsData.third_message}
                        fourth_message={CouponConfigurationsData.fourth_message}
                        first_subject={CouponConfigurationsData.first_subject}
                        second_subject={CouponConfigurationsData.second_subject}
                        third_subject={CouponConfigurationsData.third_subject}
                        fourth_subject={CouponConfigurationsData.fourth_subject}
                        sendRefreshToParent={setRefresh}
                        refresh={refresh}
                        sendHiddenToParent={setHidden}
                        hidden={hidden}
                    />
                </div>
                <div className={`row m-0 justify-content-center ${!hidden ? "d-none" : "d-block"}`}>
                    <div onClick={setFormHidden}
                         className="edit-holder c-pointer d-flex align-items-center justify-content-end">
                        <div className="icon-holder me-2"><BiEdit className="icon" size="25"/></div>
                        <div className="edit-text">Edit</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <div className="small text-black-50">
                                    Discount Percentage
                                </div>
                                <div className="setting-value">
                                    {CouponConfigurationsData.discount}%
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <div className="small text-black-50">
                                    Time to send first message
                                </div>
                                <div className="setting-value">
                                    {CouponConfigurationsData.first_message_time} Minutes
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mb-3">
                                <div className="small text-black-50">
                                    Time to send second message
                                </div>
                                <div className="setting-value">
                                    {CouponConfigurationsData.second_message_time} Hours
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <div className="small text-black-50">
                                    First subject
                                </div>
                                <div className="setting-value">
                                    {CouponConfigurationsData.first_subject}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <div className="small text-black-50">
                                    Text of first message
                                </div>
                                <div className="setting-value">
                                    {CouponConfigurationsData.first_message}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <div className="small text-black-50">
                                    Second subject
                                </div>
                                <div className="setting-value">
                                    {CouponConfigurationsData.second_subject}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <div className="small text-black-50">
                                    Text of second message
                                </div>
                                <div className="setting-value">
                                    {CouponConfigurationsData.second_message}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <div className="small text-black-50">
                                    Third subject
                                </div>
                                <div className="setting-value">
                                    {CouponConfigurationsData.third_subject}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <div className="small text-black-50">
                                    Text of third message (When 48hours remaining)
                                </div>
                                <div className="setting-value">
                                    {CouponConfigurationsData.third_message}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <div className="small text-black-50">
                                    Fourth subject
                                </div>
                                <div className="setting-value">
                                    {CouponConfigurationsData.fourth_subject}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <div className="small text-black-50">
                                    Text of fourth message (When 24hours remaining)
                                </div>
                                <div className="setting-value">
                                    {CouponConfigurationsData.fourth_message}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <></>
        )
    }
}

export default CouponsConfigurations;