import {Header, UserRow} from "./Admin";
import {useEffect, useState} from "react";
import {userService} from "../../_services";

const Users = () => {
    const [usersData, setData] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await userService.getUsers();
                setData(data);
            } catch (error) {
                console.error("Error fetching users data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    return (
        <>
            <Header title="Users"/>
            <div className="page-content p-2 p-lg-3">
                <div className="main-users-page">
                    <div className="big-title mb-2 mb-lg-3">Users List</div>
                    <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3">
                        <table className="table">
                            <thead>
                            <tr>
                                <td className="text-black-50">ID</td>
                                <td className="text-black-50">Name</td>
                                <td className="text-black-50">Email</td>
                                <td className="text-black-50">Status</td>
                                <td className="text-black-50">Registered</td>
                                <td className="text-black-50">Actions</td>
                            </tr>
                            </thead>
                            <tbody>
                            {usersData.length > 0 ? (
                                usersData.map((user) => (
                                    <UserRow
                                        key={user.id}
                                        user_id={user.id}
                                        full_name={user.full_name || ""}
                                        status={user.status}
                                        email={user.email}
                                        created_date={user.created_date}
                                        sendRefreshToParent={setRefresh}
                                        refresh={refresh}
                                    />
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="text-center">There is no user yet</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Users;