import {Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import React, {useEffect} from "react";

const DiscountModal = ({
                           pricingPlansData,
                           showDiscountModal,
                           setShowDiscountModal,
                           handleDiscountModalClose,
                           showCouponModal,
                           discountApplied,
                           alreadyClicked
                       }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!showCouponModal && !discountApplied && !alreadyClicked) {
                setShowDiscountModal(true);
            }
        }, 30000);
        return () => clearTimeout(timer);
    }, [showCouponModal, discountApplied, alreadyClicked]);

    return (
        <Modal
            show={showDiscountModal}
            onHide={handleDiscountModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="p-0" style={{width: "100%", maxWidth: "475px", margin: "auto"}}>
                <div className="light-shadow rounded-1 position-relative px-2 py-3">
                    <div className="position-absolute top-0 end-0 translate-middle-y pe-2">
                        <button type="button" onClick={handleDiscountModalClose}
                                className="border-1 border-light-subtle py-1 px-2 rounded-3 bg-light main-text-color small">
                            Close
                        </button>
                    </div>
                    <div className="text-center fw-bold main-text-color mb-2">
                        Want to Access Skillspay for FREE?
                    </div>
                    <div className="gray-text-color px-2 small lh-base mb-2">
                        We are pleased to announce the launch of our grants program, made possible by our generous
                        sponsors. A limited number of slots are available for applicants to receive discounts of up to
                        100%. We encourage you to apply today for the opportunity to benefit from this program.
                    </div>
                    {pricingPlansData.length > 0 && (
                        <div className="main-text-color fw-bold fs-5 text-center mb-2">
                            <span className="text-decoration-line-through fw-bold-500 pe-1">
                                {localStorage.getItem('skillspay_chosen_currency_name') && localStorage.getItem('skillspay_chosen_currency') && localStorage.getItem('skillspay_chosen_currency') !== '1' ? (
                                    <>
                                        {`${Math.floor(pricingPlansData[0].sale_price / localStorage.getItem('skillspay_chosen_currency')).toLocaleString()} ${localStorage.getItem('skillspay_chosen_currency_name')}`}*
                                    </>
                                ) : (
                                    <>
                                        ${pricingPlansData[0].sale_price.toLocaleString()}
                                    </>
                                )}
                            </span> $0.00
                        </div>
                    )}
                    <div className="text-center">
                        <Link to="/discount-opportunity"
                              className="bg-orange-color px-5 py-2 d-inline-block rounded-3 fw-bold text-white">
                            Apply Now
                        </Link>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DiscountModal;