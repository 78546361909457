import Axios from "./ServiceCaller";

let login = (credentials) => {
    return Axios.post('/auth/login', credentials);
};

let saveQuizUser = (quizData) => {
    return Axios.post('/auth/quiz-users', quizData);
};

let saveToken = (token) => {
    localStorage.setItem('token', token);
};

let saveQuizToken = (quizToken) => {
    localStorage.setItem('quizToken', quizToken);
};

let isQuizDone = () => {
    let quizToken = localStorage.getItem('quizToken');
    return !!quizToken;
};

let getQuizToken = () => {
    return localStorage.getItem('quizToken');
};

let getUserData = async () => {
    const {data} = await Axios.get('/users/user-data');
    return data;
};

let isLogged = () => {
    let token = localStorage.getItem('token');
    return !!token;
};

let getToken = () => {
    return localStorage.getItem('token');
};

let isAdmin = async () => {
    const {data} = await Axios.get('/users/is-admin');
    return data.User;
};

let isSingleAccess = async () => {
    const {data} = await Axios.get('/users/is-single-access');
    return data.UserAccess;
};

let logout = () => {
    localStorage.removeItem('token');
};

let discountOpportunityApplication = (userDetails) => {
    return Axios.post('/auth/discount-opportunity', userDetails);
};

let checkCouponCodeValidity = (coupon) => {
    return Axios.post('/auth/check-coupon', coupon);
};

export const accountService = {
    login,
    saveToken,
    isLogged,
    isAdmin,
    isSingleAccess,
    getToken,
    logout,
    getUserData,
    saveQuizUser,
    saveQuizToken,
    isQuizDone,
    getQuizToken,
    discountOpportunityApplication,
    checkCouponCodeValidity,
};