import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {accountService} from "../../../_services";

const CouponApplyModal = ({
                              pricingPlansData,
                              showCouponModal,
                              handleCouponModalClose,
                              updatePricingPlans,
                              sendRefreshToParent,
                              refresh,
                              discountCode,
                              setDiscountCode,
                              couponDetails,
                              setCouponDetails,
                              setDiscountApplied
                          }) => {
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleFetchCoupon = async () => {
        setError('');
        setCouponDetails(null);
        setIsLoading(true);

        accountService.checkCouponCodeValidity({coupon: discountCode})
            .then((res) => {
                if (res.data.status === 200 && res.data.discountValue && res.data.expirationDate) {
                    setCouponDetails({
                        discountValue: res.data.discountValue,
                        expirationDate: res.data.expirationDate,
                    });
                } else {
                    setError(res.data.message)
                }
            })
            .catch(() => setError('Something\'s wrong! try later!'))
            .finally(() => setIsLoading(false));
    };

    const handleApplyCoupon = () => {
        if (couponDetails) {
            const updatedPricingPlans = pricingPlansData.map(plan => ({
                ...plan,
                sale_price: plan.sale_price - (plan.sale_price * (couponDetails.discountValue / 100)),
            }));
            updatePricingPlans(updatedPricingPlans);
            handleCouponModalClose();
            setDiscountApplied(true)
            sendRefreshToParent(!refresh);
        }
    };

    return (
        <Modal
            show={showCouponModal}
            onHide={handleCouponModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="p-0" style={{width: '100%', maxWidth: '475px', margin: 'auto'}}>
                <div className="light-shadow rounded-1 position-relative px-2 py-3">
                    <div className="position-absolute top-0 end-0 translate-middle-y pe-2">
                        <button
                            type="button"
                            onClick={handleCouponModalClose}
                            className="border-1 border-light-subtle py-1 px-2 rounded-3 bg-light main-text-color small"
                        >
                            Close
                        </button>
                    </div>

                    {!couponDetails ? (
                        <>
                            <div className="text-center fw-bold main-text-color mb-2">
                                Have a discount code?
                            </div>
                            <div className="px-2 mb-3 discount-opportunity-page">
                                <label htmlFor="coupon" className="small main-text-color ps-1 mb-1">
                                    Enter it Here
                                </label>
                                <input
                                    maxLength="8"
                                    type="text"
                                    id="coupon"
                                    className="form-control"
                                    placeholder="coupon code"
                                    value={discountCode}
                                    onChange={(e) => setDiscountCode(e.target.value)}
                                />
                            </div>
                            {error && <div className="text-danger text-center mb-3 small">{error}</div>}
                            <div className="text-center">
                                <button
                                    onClick={handleFetchCoupon}
                                    className="bg-orange-color px-5 py-2 d-inline-block rounded-3 fw-bold text-white border-0"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Checking...' : 'Check Code'}
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="text-center fw-bold main-text-color mb-2">
                                🎉 Congrats! Your coupon is valid.
                            </div>
                            <div className="text-center mb-3">
                                <div className="fw-bold mb-1">Discount: {couponDetails.discountValue}%</div>
                                <div className="small main-text-color">Expires on: {couponDetails.expirationDate}</div>
                            </div>
                            <div className="text-center">
                                <button
                                    onClick={handleApplyCoupon}
                                    className="bg-orange-color px-5 py-2 d-inline-block rounded-3 fw-bold text-white border-0"
                                >
                                    Apply Coupon
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CouponApplyModal;