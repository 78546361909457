import React, {useEffect, useState} from "react";
import {couponService} from "../../../_services";
import {IoWarning} from "react-icons/io5";

const CouponsConfigurationsForm = (props) => {
    const [isCreating, setIsCreating] = useState(false);

    const [params, setParamsValues] = useState({
        discount: "",
        first_message_time: "",
        second_message_time: "",
        first_message: "",
        second_message: "",
        third_message: "",
        fourth_message: "",
        first_subject: "",
        second_subject: "",
        third_subject: "",
        fourth_subject: "",
    });

    useEffect(() => {
        setParamsValues({
            discount: props.discount,
            first_message_time: props.first_message_time,
            second_message_time: props.second_message_time,
            first_message: props.first_message,
            second_message: props.second_message,
            third_message: props.third_message,
            fourth_message: props.fourth_message,
            first_subject: props.first_subject,
            second_subject: props.second_subject,
            third_subject: props.third_subject,
            fourth_subject: props.fourth_subject,
        });
    }, [props]);

    const onChange = (e) => {
        setParamsValues({
            ...params,
            [e.target.name]: e.target.value,
        });
    };

    const setFormHidden = () => {
        props.sendHiddenToParent(!props.hidden);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsCreating(true);
        try {
            const formData = new FormData();
            formData.append("settings_id", props.settings_id);
            formData.append("discount", params.discount);
            formData.append("first_message_time", params.first_message_time);
            formData.append("second_message_time", params.second_message_time);
            formData.append("first_message", params.first_message);
            formData.append("second_message", params.second_message);
            formData.append("third_message", params.third_message);
            formData.append("fourth_message", params.fourth_message);
            formData.append("first_subject", params.first_subject);
            formData.append("second_subject", params.second_subject);
            formData.append("third_subject", params.third_subject);
            formData.append("fourth_subject", params.fourth_subject);
            await couponService.updateCouponConfigurations(formData);
            props.sendRefreshToParent(!props.refresh);
            props.sendHiddenToParent(!props.hidden);
        } catch (error) {
            console.error("Error Updating Coupon Configurations Data:", error);
        } finally {
            setIsCreating(false);
        }
    };

    return (
        <div className="main-form-holder add-chapter-holder bg-white rounded-3 py-3 px-2 px-lg-3 mb-4">
            <div className="big-title text-center mb-3">Edit Configurations Data</div>
            <div className="bg-warning-subtle rounded-2 p-2 mb-3 mx-auto" style={{width: "fit-content"}}>
                <div className="border bg-light-subtle fw-bold-500 main-text-color p-3 rounded-2 mx-auto mb-2"
                     style={{width: 'fit-content'}}>
                    <IoWarning size="25" className="text-warning me-2 mb-1"/>
                    {"Please use exact {name}, {coupon_code}, {discount}, {expiration_date} to got the values dynamic"}
                    <IoWarning size="25" className="text-warning ms-2 mb-1"/>
                </div>
                <div className="border bg-light-subtle fw-bold-500 main-text-color p-3 rounded-2 mx-auto"
                     style={{width: 'fit-content'}}>
                    <IoWarning size="25" className="text-danger me-2 mb-1"/>
                    {"Don't never use '{' or '}' anywhere else"}
                    <IoWarning size="25" className="text-danger ms-2 mb-1"/>
                </div>
            </div>
            <div className="form-holder">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group mb-3">
                                <label className="mb-1 small" htmlFor="discount">
                                    Discount Percentage (%) (1 - 100):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            type="number"
                                            className="form-control text py-2"
                                            value={params.discount}
                                            onChange={onChange}
                                            name="discount"
                                            id="discount"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group mb-3">
                                <label className="mb-1 small" htmlFor="first_message_time">
                                    Time to send first message (in Minutes):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control text py-2"
                                            value={params.first_message_time}
                                            onChange={onChange}
                                            name="first_message_time"
                                            id="first_message_time"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group mb-3">
                                <label className="mb-1 small" htmlFor="second_message_time">
                                    Time to send second message (in Hours):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            type="text"
                                            className="form-control text py-2"
                                            value={params.second_message_time}
                                            onChange={onChange}
                                            name="second_message_time"
                                            id="second_message_time"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group mb-3">
                                <label className="mb-1 small" htmlFor="first_subject">
                                    First subject:
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            className="form-control text p-2"
                                            value={params.first_subject}
                                            onChange={onChange}
                                            name="first_subject"
                                            id="first_subject"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group mb-3">
                                <label className="mb-1 small" htmlFor="first_message">
                                    Text of first message (only {"{name}"}):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                    <textarea
                                        rows="4"
                                        className="form-control text p-2"
                                        value={params.first_message}
                                        onChange={onChange}
                                        name="first_message"
                                        id="first_message"
                                        required
                                    ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group mb-3">
                                <label className="mb-1 small" htmlFor="second_subject">
                                    Second subject:
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            className="form-control text p-2"
                                            value={params.second_subject}
                                            onChange={onChange}
                                            name="second_subject"
                                            id="second_subject"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group mb-3">
                                <label className="mb-1 small" htmlFor="second_message">
                                    Text of second message ({"{name}, {coupon_code}, {discount}, {expiration_date}"}):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                    <textarea
                                        rows="4"
                                        className="form-control text p-2"
                                        value={params.second_message}
                                        onChange={onChange}
                                        name="second_message"
                                        id="second_message"
                                        required
                                    ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group mb-3">
                                <label className="mb-1 small" htmlFor="third_subject">
                                    Third subject:
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            className="form-control text p-2"
                                            value={params.third_subject}
                                            onChange={onChange}
                                            name="third_subject"
                                            id="third_subject"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group mb-3">
                                <label className="mb-1 small" htmlFor="third_message">
                                    Text of third message (48hours remaining)
                                    ({"{name}, {coupon_code}, {discount}, {expiration_date}"}):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                    <textarea
                                        rows="4"
                                        className="form-control text p-2"
                                        value={params.third_message}
                                        onChange={onChange}
                                        name="third_message"
                                        id="third_message"
                                        required
                                    ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group mb-3">
                                <label className="mb-1 small" htmlFor="fourth_subject">
                                    Fourth subject:
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <input
                                            className="form-control text p-2"
                                            value={params.fourth_subject}
                                            onChange={onChange}
                                            name="fourth_subject"
                                            id="fourth_subject"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group mb-3">
                                <label className="mb-1 small" htmlFor="fourth_message">
                                    Text of fourth message (24hours remaining)
                                    ({"{name}, {coupon_code}, {discount}, {expiration_date}"}):
                                </label>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                    <textarea
                                        rows="4"
                                        className="form-control text p-2"
                                        value={params.fourth_message}
                                        onChange={onChange}
                                        name="fourth_message"
                                        id="fourth_message"
                                        required
                                    ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isCreating ? (
                        <span className="text-danger">Loading...</span>
                    ) : (
                        <div className="text-end">
                            <button type="button" className="btn btn-secondary me-3" onClick={setFormHidden}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary px-4">
                                Save
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default CouponsConfigurationsForm;
