import {Modal} from "react-bootstrap";
import {couponService} from "../../../_services";
import {useState} from "react";
import Button from "react-bootstrap/Button";
import {BiTrash} from "react-icons/bi";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}

const CouponRow = (props) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleDeleteCoupon = async () => {
        setIsDeleting(true);

        try {
            await couponService.deleteCoupon(props.coupon_id);
            props.sendRefreshToParent(!props.refresh);
        } catch (error) {
            console.error("Error deleting coupon:", error);
        } finally {
            setIsDeleting(false);
            setShowConfirmModal(false);
        }
    };

    return (
        <>
            <tr className="text-nowrap">
                <td>
                    {`${props.full_name.substring(0, 25)}${
                        props.full_name.length > 25 ? "..." : ""
                    }`}
                </td>
                <td>
                    {props.country}
                </td>
                <td>
                    {`${props.message.substring(0, 25)}${
                        props.message.length > 25 ? "..." : ""
                    }`}
                </td>
                <td>
                    {props.starting}
                </td>
                <td>
                    {props.committing}
                </td>
                <td>
                    {props.coupon_code}
                </td>
                <td>
                    {props.status}
                </td>
                <td>
                    <span className="text-black-50">{props.expiration_date ? getDate(props.expiration_date) : ''}</span>
                </td>
                <td>
                    <button
                        className="btn btn-link"
                        onClick={props.onShowDetails}
                    >
                        Details
                    </button>
                    <button
                        className="border-0 bg-transparent"
                        onClick={() => setShowConfirmModal(true)}
                    >
                        <BiTrash size="22" className="remove-icon mx-auto"/>
                    </button>
                </td>
            </tr>
            {/* Confirmation Modal */}
            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this coupon?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowConfirmModal(false)}
                        disabled={isDeleting}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={handleDeleteCoupon}
                        disabled={isDeleting}
                    >
                        {isDeleting ? "Deleting..." : "Delete"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CouponRow;