import "./public.css";
import logoBrand from "../../assets/images/logo.png";
import React, {useEffect, useState} from "react";
import {accountService, paymentService, pricingPlanService} from "../../_services";
import {Link, useNavigate} from "react-router-dom";
import money_back_icon from "../../assets/images/deal/money_back_icon.png";
import {ChangeCurrency, DiscountModal, PricingPlanRow, VideoModal, CouponApplyModal} from "./Public";
import {BsPlay} from "react-icons/bs";
import {AiFillStar} from "react-icons/ai";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination} from "swiper/modules";

import testimonial_pic1 from "../../assets/images/public/testimonial_pic1.png";
import testimonial_pic2 from "../../assets/images/public/testimonial_pic2.png";
import testimonial_pic3 from "../../assets/images/public/testimonial_pic3.png";
import testimonial_pic4 from "../../assets/images/public/testimonial_pic4.png";
import CreateAccount from "../../components/public/plans/CreateAccount";
import PaymentMethods from "../../components/public/plans/PaymentMethods";
import OrderSuccessful from "../../components/public/plans/OrderSuccessful";
import payment_logo_1 from "../../assets/images/payments/payment_logo_1.jpg";
import payment_logo_2 from "../../assets/images/payments/payment_logo_2.jpg";
import payment_logo_3 from "../../assets/images/payments/payment_logo_3.jpg";
import payment_logo_4 from "../../assets/images/payments/payment_logo_4.jpg";
import payment_logo_5 from "../../assets/images/payments/payment_logo_5.jpg";
import payment_logo_6 from "../../assets/images/payments/payment_logo_6.jpg";
import payment_logo_7 from "../../assets/images/payments/payment_logo_7.jpg";
import payment_logo_8 from "../../assets/images/payments/payment_logo_8.jpg";
import payment_logo_9 from "../../assets/images/payments/payment_logo_9.jpg";

const PricingPlans = () => {
    useEffect(() => {
        document.title = "Skillspay - Pricing Plans";
    }, []);
    let navigate = useNavigate();
    useEffect(() => {
        if (accountService.isLogged()) {
            return (
                navigate('/courses', {replace: true})
            );
        }
    }, [navigate]);

    const [keysData, setData] = useState([]);
    const [pricingPlansData, setPricingPlansData] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const [holderStep, setHolderStep] = useState('plans');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await paymentService.getPaypalClientId();
                const plansData = await pricingPlanService.getPricingPlans();
                setData(data);
                setPricingPlansData(plansData)
            } catch (error) {
                console.error("Error fetching PayPal Client ID:", error);
            }
        };

        fetchData();
    }, []);

    const clientID = keysData.client_id || '';

    const [planDetails, setPlanDetails] = useState({
        duration_type: '',
        duration: '',
        course_id: '',
        pdf_course_id: '',
        product_name: '',
        price: '',
        method_payment: '',
    });

    const [userDetails, setUserDetails] = useState({
        email: '',
        full_name: '',
        password: '',
        confirm_password: '',
    });

    const [paymentMethod, setPaymentMethod] = useState('')
    const [videoLink, setVideoLink] = useState(true);
    const [hidden, setHidden] = useState(true);
    const setVideoModalHidden = (videoLink) => {
        setVideoLink(videoLink)
        setHidden(!hidden);
    }
    const setHolderStepValue = (step) => {
        setHolderStep(step)
    }

    const [showDiscountModal, setShowDiscountModal] = useState(false);
    const [showCouponModal, setShowCouponModal] = useState(false);
    const [discountApplied, setDiscountApplied] = useState(false);
    const [discountCode, setDiscountCode] = useState('');
    const [couponDetails, setCouponDetails] = useState(null);
    const [alreadyClicked, setAlreadyClicked] = useState(false);

    const handleDiscountModalClose = () => {
        setShowDiscountModal(false);
    };

    const handleCouponModalClose = () => {
        setShowCouponModal(false);
    };

    return (
        <div className="pricing-plans-page h-100 mx-auto" style={{minHeight: '100vh', maxWidth: '500px'}}>
            <div className="pricing-plans-header d-flex align-items-center justify-content-between px-3 bg-white">
                <Link to="/" className="logo-holder text-center">
                    <img className="img-fluid" src={logoBrand} alt="skills pay"/>
                </Link>
                {(holderStep !== 'order-success' && !discountApplied) && (
                    <div>
                        <button className="small border-0 bg-orange-color text-white py-2 px-3 rounded-2"
                                onClick={() => setShowCouponModal(true)}>
                            Apply Coupon
                        </button>
                    </div>
                )}
            </div>
            {holderStep === 'plans' && (
                <div className="plan-choose-step">
                    <div className="title-holder p-2 pt-3">
                        <div className="page-title fw-bold text-center mb-2 px-3 lh-sm">Get Premium Access</div>
                        <div className="text-center big-title fw-bold px-3 lh-sm fs-6">The Future Belongs to Those Who
                            Take
                            Action Today!
                        </div>
                    </div>
                    {discountApplied ? (
                        <div className="px-3 mb-3">
                            <div className="rounded-3 py-2 shadow-sm" style={{backgroundColor: 'rgb(124 232 125 / 30%)'}}>
                                <div className="text-center fw-bold main-text-color mb-1">
                                    🎉 Congrats!
                                </div>
                                <div className="text-center">
                                    <div className="fw-bold mb-1">
                                        Discount: {couponDetails.discountValue}%
                                    </div>
                                    <div className="small main-text-color">
                                        Valid till: {couponDetails.expirationDate}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="px-3 mb-3">
                            <div
                                className="small fw-bold-500 px-3 text-center main-text-color bg-white rounded-3 py-2 shadow-sm">
                                <span className="fw-bold-600 orange-text-color">Grants Available:</span> Apply for a possibility of having
                                Full
                                Access to Skillspay at $0.00<br/>
                                <button type="button" className="orange-text-color fw-bold fs-6 border-0 bg-transparent"
                                        onClick={() => {
                                            setShowDiscountModal(true);
                                            setAlreadyClicked(true);
                                        }}>
                                    Apply Now
                                </button>
                            </div>
                        </div>
                    )}
                    <ChangeCurrency
                        refresh={refresh}
                        sendRefreshToParent={setRefresh}
                    />
                    <div className="plans-holder p-3 pb-0">
                        {pricingPlansData.length > 0 ? (
                            pricingPlansData.map((pricingPlan) => (
                                <PricingPlanRow
                                    key={pricingPlan.id}
                                    plan_id={pricingPlan.id}
                                    plan_name={pricingPlan.plan_name}
                                    duration_type={pricingPlan.duration_type}
                                    duration={pricingPlan.duration}
                                    regular_price={pricingPlan.regular_price}
                                    sale_price={pricingPlan.sale_price}
                                    description={pricingPlan.description}
                                    clientID={clientID}
                                    setHolderStep={setHolderStepValue}
                                    planDetails={planDetails}
                                    setPlanDetails={setPlanDetails}
                                />
                            ))
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="mt-4 px-2 main-text-color text-center pb-4">
                        We accept payment in <span className="fw-bold-600">100+</span> currencies
                    </div>
                    <div className="pb-2">
                        <div
                            className="logos-holder bg-white p-2 shadow-sm d-flex flex-wrap align-items-center justify-content-center">
                            <div className="logo-holder mx-1 mb-2">
                                <img src={payment_logo_1} alt=""/>
                            </div>
                            <div className="logo-holder mx-1 mb-2">
                                <img src={payment_logo_2} alt=""/>
                            </div>
                            <div className="logo-holder mx-1 mb-2">
                                <img src={payment_logo_3} alt=""/>
                            </div>
                            <div className="logo-holder mx-1 mb-2">
                                <img src={payment_logo_4} alt=""/>
                            </div>
                            <div className="logo-holder mx-1 mb-2">
                                <img src={payment_logo_5} alt=""/>
                            </div>
                            <div className="logo-holder mx-1 mb-2">
                                <img src={payment_logo_6} alt=""/>
                            </div>
                            <div className="logo-holder mx-1 mb-2">
                                <img src={payment_logo_7} alt=""/>
                            </div>
                            <div className="logo-holder mx-1 mb-2">
                                <img src={payment_logo_8} alt=""/>
                            </div>
                            <div className="logo-holder mx-1 mb-2">
                                <img src={payment_logo_9} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="testimonials-section mt-3 pt-2 pb-3 px-2 shadow-sm">
                        <div className="main-text-color fw-bold fs-5 text-center mb-1">Don’t take our word for it…
                        </div>
                        <div className="main-text-color small text-center mb-2">Testimonies from past students.
                        </div>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={15}
                            loop={true}
                            className="myTestimonialSwiper"
                            pagination={true}
                            modules={[Autoplay, Pagination]}
                        >
                            <SwiperSlide>
                                <div className="testimonial-box px-4">
                                    <div className="box-content rounded-3"
                                         onClick={() => setVideoModalHidden('https://player.vimeo.com/video/955012948')}>
                                        <div className="img-holder rounded-3 position-relative">
                                            <img className="rounded-top-3 w-100 object-fit-cover" height="550"
                                                 src={testimonial_pic1} alt="Sarah W."/>
                                            <div
                                                className="position-absolute w-100 bottom-0 start-0 p-3 custom-overlay-black">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div
                                                        className="d-flex align-items-center justify-content-center">
                                                        <BsPlay size="50" color="#fff"/>
                                                    </div>
                                                    <div className="review-holder">
                                                        <div className="name text-white fs-5">
                                                            Sarah W.
                                                        </div>
                                                        <div className="review-stars">
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-3 rounded-bottom-3 pb-4"
                                             style={{backgroundColor: '#E0B703', height: '90px', fontSize: '17px'}}>
                                            "Skillspay changed my life"
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-box px-4">
                                    <div className="box-content rounded-3"
                                         onClick={() => setVideoModalHidden('https://player.vimeo.com/video/955012273')}>
                                        <div className="img-holder rounded-3 position-relative">
                                            <img className="rounded-top-3 w-100 object-fit-cover" height="550"
                                                 src={testimonial_pic2} alt="Peter B."/>
                                            <div
                                                className="position-absolute w-100 bottom-0 start-0 p-3 custom-overlay-black">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div
                                                        className="d-flex align-items-center justify-content-center">
                                                        <BsPlay size="50" color="#fff"/>
                                                    </div>
                                                    <div className="review-holder">
                                                        <div className="name text-white fs-5">
                                                            Peter B.
                                                        </div>
                                                        <div className="review-stars">
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-3 rounded-bottom-3 pb-4"
                                             style={{backgroundColor: '#E0B703', height: '90px', fontSize: '17px'}}>
                                            "Skillspay gave me an opportunity I’ll never forget"
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-box px-4">
                                    <div className="box-content rounded-3"
                                         onClick={() => setVideoModalHidden('https://player.vimeo.com/video/955009904')}>
                                        <div className="img-holder rounded-3 position-relative">
                                            <img className="rounded-top-3 w-100 object-fit-cover" height="550"
                                                 src={testimonial_pic3} alt="Donald M."/>
                                            <div
                                                className="position-absolute w-100 bottom-0 start-0 p-3 custom-overlay-black">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div
                                                        className="d-flex align-items-center justify-content-center">
                                                        <BsPlay size="50" color="#fff"/>
                                                    </div>
                                                    <div className="review-holder">
                                                        <div className="name text-white fs-5">
                                                            Donald M.
                                                        </div>
                                                        <div className="review-stars">
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-3 rounded-bottom-3 pb-4"
                                             style={{backgroundColor: '#E0B703', height: '90px', fontSize: '17px'}}>
                                            "Skillspay is the ultimate resource for success and a game changer"
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-box px-4">
                                    <div className="box-content rounded-3"
                                         onClick={() => setVideoModalHidden('https://player.vimeo.com/video/955010794')}>
                                        <div className="img-holder rounded-3 position-relative">
                                            <img className="rounded-top-3 w-100 object-fit-cover" height="550"
                                                 src={testimonial_pic4} alt="Cyndy F."/>
                                            <div
                                                className="position-absolute w-100 bottom-0 start-0 p-3 custom-overlay-black">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div
                                                        className="d-flex align-items-center justify-content-center">
                                                        <BsPlay size="50" color="#fff"/>
                                                    </div>
                                                    <div className="review-holder">
                                                        <div className="name text-white fs-5">
                                                            Cyndy F.
                                                        </div>
                                                        <div className="review-stars">
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                            <AiFillStar size="25" color="#E0B703"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-3 rounded-bottom-3 pb-4"
                                             style={{backgroundColor: '#E0B703', height: '90px', fontSize: '17px'}}>
                                            "Thanks for the opportunity to learn and earn"
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="money-back-holder rounded-2 my-4 px-3 py-4 text-center">
                        <div className="icon-holder text-center mb-3"><img src={money_back_icon} alt="money back"/>
                        </div>
                        <div className="big-title fw-bold mb-3 fs-5">Money-Back Guarantee</div>
                        <div className="content-holder">
                            <p className="text-color small px-3">We are so confident in our service that we are ready to
                                offer a
                                full refund within 30 days of
                                purchase if you do not achieve initial results and can demonstrate you have followed the
                                plan.</p>
                            <p className="text-color small">Learn more about all the conditions in our<br/><span
                                className="text-decoration-underline">Subscription Terms.</span></p>

                        </div>
                    </div>
                    <div className={hidden ? 'video-modal-container hidden' : 'video-modal-container'}>
                        <VideoModal
                            url={videoLink}
                            sendHiddenToParent={setHidden}
                            hidden={hidden}
                            setVideoLink={setVideoLink}
                        />
                    </div>
                </div>
            )}
            {holderStep === 'account' && (
                <div className="create-account-step">
                    <CreateAccount
                        userDetails={userDetails}
                        setUserDetails={setUserDetails}
                        setHolderStep={setHolderStepValue}
                    />
                </div>
            )}
            {holderStep === 'payment' && (
                <div className="payment-method-step">
                    <PaymentMethods
                        duration_type={planDetails.duration_type}
                        duration={planDetails.duration}
                        course_id={planDetails.courseId}
                        pdf_course_id={planDetails.pdf_course_id}
                        client_id={clientID}
                        product_name={planDetails.product_name}
                        price={planDetails.price}
                        userDetails={userDetails}
                        setPaymentMethod={setPaymentMethod}
                        setHolderStep={setHolderStepValue}
                    />
                </div>
            )}
            {holderStep === 'order-success' && (
                <div className="order-success-step">
                    <OrderSuccessful
                        price={planDetails.price}
                        method_payment={paymentMethod}
                    />
                </div>
            )}

            <DiscountModal
                pricingPlansData={pricingPlansData}
                showDiscountModal={showDiscountModal}
                showCouponModal={showCouponModal}
                discountApplied={discountApplied}
                alreadyClicked={alreadyClicked}
                setShowDiscountModal={setShowDiscountModal}
                handleDiscountModalClose={handleDiscountModalClose}
            />

            <CouponApplyModal
                pricingPlansData={pricingPlansData}
                showCouponModal={showCouponModal}
                handleCouponModalClose={handleCouponModalClose}
                updatePricingPlans={setPricingPlansData}
                refresh={refresh}
                sendRefreshToParent={setRefresh}
                setDiscountApplied={setDiscountApplied}
                discountCode={discountCode}
                setDiscountCode={setDiscountCode}
                couponDetails={couponDetails}
                setCouponDetails={setCouponDetails}
            />
        </div>
    );
}

export default PricingPlans;