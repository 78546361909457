import React, {useState} from "react";
import logoBrand from "../../assets/images/logo.png";
import {accountService} from "../../_services";
import {AiOutlineFileDone} from "react-icons/ai";
import {AllCountriesSelectOptions} from "./Public";
import {Link} from "react-router-dom";

const DiscountOpportunity = () => {
    const [loading, setLoading] = useState(false);
    const [isApplied, setIsApplied] = useState(false);
    const [userDetails, setUserDetails] = useState({
        full_name: "",
        email: "",
        phone_number: "",
        country: "",
        message: "",
        starting: "",
        committing: "",
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setUserDetails({...userDetails, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        accountService.discountOpportunityApplication(userDetails)
            .then((res) => {
                if (res.data.status === 200) {
                    setIsApplied(true)
                }
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
    };

    return (
        <div
            className="discount-opportunity-page h-100 mx-auto"
            style={{minHeight: "100vh", maxWidth: "500px"}}
        >
            <div className="d-flex align-items-center justify-content-between px-3 bg-white">
                <Link to="/" className="logo-holder text-center w-100">
                    <img className="mx-auto" src={logoBrand} alt="skills pay" height="70"/>
                </Link>
            </div>
            {isApplied ? (
                <div className="pt-5 px-3">
                    <div className="text-center">
                        <AiOutlineFileDone size="70" color="#6bbb39"/>
                    </div>
                    <div className="main-text-color text-center fw-bold mt-2 mb-3">
                        Application Submitted Successfully!
                    </div>
                    <div className="small gray-text-color text-center">
                        Thank you for applying!<br/>Our team will review your application and get back to you
                        shortly.<br/>
                        If you have any questions, feel free to contact our support team.
                    </div>
                    <div className="text-center mt-4">
                        <Link to="/" className="bg-orange-color py-2 px-4 rounded-3 fw-bold-500 text-white">
                            Go Back Home
                        </Link>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="fw-bold orange-text-color fs-5 text-center mt-4 mb-1">
                        Submit Your Application Now
                    </div>
                    <div className="text-center small px-3 main-text-color mb-3">
                        Fill the form below to apply for upto 100% grants giving you access to Skillspay for FREE
                    </div>
                    <div className="px-2 pb-5">
                        <div className="bg-white p-3 rounded-3 shadow">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label htmlFor="full_name" className="small main-text-color fw-bold-500 mb-1">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control py-2"
                                        id="full_name"
                                        name="full_name"
                                        value={userDetails.full_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="email" className="small main-text-color fw-bold-500 mb-1">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control py-2"
                                        id="email"
                                        name="email"
                                        value={userDetails.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="phone_number" className="small main-text-color fw-bold-500 mb-1">
                                        Phone Number
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control py-2"
                                        id="phone_number"
                                        name="phone_number"
                                        value={userDetails.phone_number}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="country" className="small main-text-color fw-bold-500 mb-1">
                                        Select Country
                                    </label>
                                    <select
                                        className="form-select py-2"
                                        id="country"
                                        name="country"
                                        value={userDetails.country}
                                        onChange={handleChange}
                                        required
                                    >
                                        <AllCountriesSelectOptions/>
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="message" className="small main-text-color fw-bold-500 mb-1">
                                        What makes you qualified for the grant?
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="message"
                                        name="message"
                                        rows="3"
                                        value={userDetails.message}
                                        onChange={handleChange}
                                        required
                                    ></textarea>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="starting" className="small main-text-color fw-bold-500 mb-1">
                                        How soon do you intend to start your training if granted access?
                                    </label>
                                    <select
                                        className="form-select py-2"
                                        id="starting"
                                        name="starting"
                                        value={userDetails.starting}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select an option</option>
                                        <option value="Immediately">Immediately</option>
                                        <option value="Less than a Week">Less than a Week</option>
                                        <option value="More than a Week">More than a Week</option>
                                    </select>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="committing" className="small main-text-color fw-bold-500 mb-1">
                                        Are you committing to finish the entire training if given access at
                                        a discount or for free?
                                    </label>
                                    <select
                                        className="form-select py-2"
                                        id="committing"
                                        name="committing"
                                        value={userDetails.committing}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select an option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <button type="submit"
                                        className="w-100 border-0 bg-orange-color text-white fw-bold py-3 rounded-2">
                                    {loading ? 'waiting...' : 'Apply'}
                                </button>
                            </form>
                            <div className="fw-bold-500 main-text-color mt-2">
                                Not interested in grants? <Link to="/" className="orange-text-color">Go Back Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DiscountOpportunity;