import {useState} from "react";
import brandLogo from "../../../assets/images/logo.png"
import brandLogoIcon from "../../../assets/images/logo_icon.png"
import {Link, NavLink} from "react-router-dom";
import {
    BiBook,
    BiHome,
    BiMoney,
    BiGroup,
    BiUser,
    BiCog,
    BiMailSend,
    BiCartAlt
} from "react-icons/bi";
import {MdOutlineLibraryBooks} from "react-icons/md";
import {RiCoupon2Line} from "react-icons/ri";
import {LuKeyRound} from "react-icons/lu";

const Sidebar = () => {
    const menuItem = [
        {
            path: "/admin/dashboard",
            name: "Dashboard",
            icon: <BiHome size="30"/>
        },
        {
            path: "/admin/users",
            name: "Users",
            icon: <BiGroup size="30"/>
        },
        {
            path: "/admin/quiz-users",
            name: "Quiz Users",
            icon: <BiUser size="30"/>
        },
        {
            path: "/admin/orders",
            name: "Orders",
            icon: <BiCartAlt size="30"/>
        },
        {
            path: "/admin/courses",
            name: "Courses",
            icon: <BiBook size="30"/>
        },
        {
            path: "/admin/pdf-courses",
            name: "PDF Courses",
            icon: <MdOutlineLibraryBooks size="30"/>
        },
        {
            path: "/admin/email-marketing",
            name: "Email Marketing",
            icon: <BiMailSend size="30"/>
        },
        {
            path: "/admin/pricing-plans",
            name: "Pricing Plans",
            icon: <BiMoney size="30"/>
        },
        {
            path: "/admin/coupons",
            name: "Coupons",
            icon: <RiCoupon2Line size="30"/>
        },
        {
            path: "/admin/keys-settings",
            name: "Keys Settings",
            icon: <LuKeyRound size="30"/>
        },
        {
            path: "/admin/settings",
            name: "Settings",
            icon: <BiCog size="30"/>
        },
    ]
    const [isOpened, setOpenedState] = useState(false);

    return (
        <div className={isOpened ? "main-sidebar" : "main-sidebar close"}>
            <header className="position-relative border-bottom d-flex align-items-center justify-content-center">
                <Link to="/courses">
                    <div className="d-flex align-items-center justify-content-center">
                        <span className="image text-center d-flex justify-content-center align-items-center">
                            <img className="img-fluid all-logo mx-auto" style={{width: "215px"}} src={brandLogo}
                                 alt="brand logo skills pay"/>
                            <img className="img-fluid icon-logo mx-auto" style={{width: "60px"}} src={brandLogoIcon}
                                 alt="brand logo skills pay"/>
                        </span>
                    </div>
                </Link>
                <span>
                    <i className='bx bx-chevron-right toggle'
                       onClick={() => setOpenedState(!isOpened)}></i>
                </span>
            </header>
            <div className="menu-bar mt-2">
                <div className="menu">
                    <div className="menu-links">
                        {
                            menuItem.map((item, index) => (
                                <div className="nav-link" key={index}>
                                    <div className="menu-item">
                                        <NavLink to={item.path} key={index} className="nav-link-item">
                                        <span className="icon">
                                            {item.icon}
                                        </span>
                                            <span className="text nav-text"
                                                  style={{display: isOpened ? "block" : "none"}}>{item.name}</span>
                                        </NavLink>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Sidebar;