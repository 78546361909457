import {Header, CouponRow, CouponsConfigurations} from "./Admin";
import {useEffect, useState} from "react";
import {couponService} from "../../_services";

const Coupons = () => {
    const [pageSection, setPageSection] = useState('list');
    const [couponsData, setData] = useState([]);
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await couponService.getCoupons();
                setData(data);
            } catch (error) {
                console.error("Error fetching coupons data:", error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleShowDetails = (coupon) => {
        setSelectedCoupon(coupon);
    };

    const handleHideDetails = () => {
        setSelectedCoupon(null);
    };

    return (
        <>
            <Header title="Coupons"/>
            {pageSection === 'list' ? (
                <div className="page-content p-2 p-lg-3">
                    <div className="main-coupons-page">
                        <div className="d-flex">
                            <div className="big-title mb-2 mb-lg-3">Coupons List</div>
                            <div className="ms-3">
                                <button
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={() => setPageSection('config')}>
                                    Coupon Configurations
                                </button>
                            </div>
                        </div>
                        {selectedCoupon && (
                            <div className="bg-white shadow-sm p-3 rounded-3 details-holder mb-3 small">
                                <h5>Coupon Details</h5>
                                <div className="d-flex flex-wrap">
                                    <div className="me-3 mb-2">
                                        <strong className="me-1">Name:</strong>
                                        {selectedCoupon.full_name}
                                    </div>
                                    <div className="me-3 mb-2">
                                        <strong className="me-1">Email:</strong>
                                        {selectedCoupon.email}
                                    </div>
                                    <div className="me-3 mb-2">
                                        <strong className="me-1">Phone Number:</strong>
                                        {selectedCoupon.phone_number}
                                    </div>
                                    <div className="me-3 mb-2">
                                        <strong className="me-1">Country:</strong>
                                        {selectedCoupon.country}
                                    </div>
                                    <div className="me-3 mb-2">
                                        <strong className="me-1">Message:</strong>
                                        {selectedCoupon.message}
                                    </div>
                                    <div className="me-3 mb-2">
                                        <strong className="me-1">Starting:</strong>
                                        {selectedCoupon.starting}
                                    </div>
                                    <div className="me-3 mb-2">
                                        <strong className="me-1">Committing:</strong>
                                        {selectedCoupon.committing}
                                    </div>
                                    <div className="me-3 mb-2">
                                        <strong className="me-1">Coupon Code:</strong>
                                        {selectedCoupon.coupon_code}
                                    </div>
                                    <div className="me-3 mb-2">
                                        <strong className="me-1">Status:</strong>
                                        {selectedCoupon.status}
                                    </div>
                                    <div className="me-3 mb-2">
                                        <strong className="me-1">Expiration Date:</strong>
                                        {selectedCoupon.expiration_date ? getDate(selectedCoupon.expiration_date) : ''}
                                    </div>
                                    <div className="me-3 mb-2">
                                        <strong className="me-1">Created Date:</strong>
                                        {getDate(selectedCoupon.created_date)}
                                    </div>
                                </div>
                                <button
                                    className="btn btn-sm btn-secondary mt-2"
                                    onClick={handleHideDetails}
                                >
                                    Cancel
                                </button>
                            </div>
                        )}
                        <div className="bg-white p-2 p-lg-3 p-xl-4 rounded-4 mb-3">
                            <table className="table">
                                <thead>
                                <tr>
                                    <td className="text-black-50">Name</td>
                                    <td className="text-black-50">Country</td>
                                    <td className="text-black-50">Message</td>
                                    <td className="text-black-50">Starting</td>
                                    <td className="text-black-50">Committing</td>
                                    <td className="text-black-50">Coupon</td>
                                    <td className="text-black-50">Status</td>
                                    <td className="text-black-50">Expiration</td>
                                    <td className="text-black-50"></td>
                                </tr>
                                </thead>
                                <tbody>
                                {couponsData.length > 0 ? (
                                    couponsData.map((coupon) => (
                                        <CouponRow
                                            key={coupon.id}
                                            coupon_id={coupon.id}
                                            full_name={coupon.full_name}
                                            email={coupon.email}
                                            phone_number={coupon.phone_number}
                                            country={coupon.country}
                                            message={coupon.message}
                                            starting={coupon.starting}
                                            committing={coupon.committing}
                                            coupon_code={coupon.coupon_code}
                                            status={coupon.status}
                                            expiration_date={coupon.expiration_date}
                                            created_date={coupon.created_date}
                                            onShowDetails={() => handleShowDetails(coupon)}
                                            sendRefreshToParent={setRefresh}
                                            refresh={refresh}
                                        />
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center">There is no coupon yet</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="page-content p-2 p-lg-3">
                    <div className="main-coupons-page">
                        <div className="d-flex">
                            <div className="big-title mb-2 mb-lg-3">Configurations</div>
                            <div className="ms-3">
                                <button
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={() => setPageSection('list')}>
                                    Coupons List
                                </button>
                            </div>
                        </div>
                        <div className="bg-white p-2 rounded-4 mb-3">
                            <CouponsConfigurations/>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Coupons;

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
}