import {Route, Routes} from "react-router-dom";

import {
    ALayout,
    Dashboard,
    Users,
    Courses,
    Course,
    Settings,
    Error,
    Lessons,
    QuizUsers,
    QuizUserDetails,
    EmailMarketing,
    EmailTemplates,
    KeysSettings,
    TargetedCampaigns,
    Orders,
    PricingPlans,
    PdfCourses,
    PdfLessons,
    CurrencySettings,
    Coupons
} from "./Admin";

const AuthRouter = () => {
    return (
        <Routes>
            <Route element={<ALayout/>}>
                <Route index element={<Dashboard/>}/>
                <Route path="dashboard" element={<Dashboard/>}/>
                <Route index path="/" element={<Dashboard/>}/>
                <Route path="users" element={<Users/>}/>
                <Route path="quiz-users" element={<QuizUsers/>}/>
                <Route path="quiz-users/user/:uid" element={<QuizUserDetails/>}/>
                <Route path="orders" element={<Orders/>}/>
                <Route path="courses" element={<Courses/>}/>
                <Route path="courses/course/:cid" element={<Course/>}/>
                <Route path="courses/course/chapter/:chid" element={<Lessons/>}/>
                <Route path="pricing-plans" element={<PricingPlans/>}/>
                <Route path="pricing-plans/currencies" element={<CurrencySettings/>}/>
                <Route path="coupons" element={<Coupons/>}/>
                <Route path="settings" element={<Settings/>}/>
                <Route path="keys-settings" element={<KeysSettings/>}/>
                <Route path="email-marketing" element={<EmailMarketing/>}/>
                <Route path="pdf-courses" element={<PdfCourses/>}/>
                <Route path="pdf-courses/pdf-course/:cid" element={<PdfLessons/>}/>
                <Route path="email-marketing/email-templates" element={<EmailTemplates/>}/>
                <Route path="email-marketing/targeted-campaigns" element={<TargetedCampaigns/>}/>
                <Route path="*" element={<Error/>}/>
            </Route>
        </Routes>
    );
}

export default AuthRouter;