import Axios from "../ServiceCaller";

let getCoupons = async () => {
    const {data} = await Axios.get("coupons");
    return data.Coupons;
};
let deleteCoupon = async (coupon_id) => {
    try {
        const response = await Axios.delete(`coupons`, {
            data: {id: coupon_id},
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting Coupon:", error);
        throw error;
    }
};
let getCouponConfigurations = async () => {
    const {data} = await Axios.get("coupons/configurations");
    return data.CouponConfigurations;
};

let updateCouponConfigurations = async (CouponConfigurationsData) => {
    const {data} = await Axios.put("coupons/configurations", CouponConfigurationsData);
    return data;
};

export const couponService = {
    getCoupons,
    deleteCoupon,
    getCouponConfigurations,
    updateCouponConfigurations
};